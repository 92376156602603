// 
// Page
//
.page {
    padding-top: 160px;
    overflow: hidden;
    position: relative;
    .page-hero {
        position: relative;
        overflow: hidden;
        padding-bottom: 32.5%;
        .page-hero-backgroung {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: cover;
            background-position: top center;
        }
    }
    .page-hero-map{
        position: relative;
        overflow: hidden;
        padding-bottom: 32.5em;
    }
    .page-title {
        padding: 35px 0;
        .breadcrumb {
            margin-bottom: 20px;
        }
    }
    .page-content {
        .container {
            padding-left: 260px;
            padding-right: 260px;
            position: relative;
        }
    }
    .page-content-pl-0 {
        .container {
            padding-left: 15px;
        }
    }
    .page-content-pr-0 {
        .container {
            padding-right: 15px;
        }
    }
}

@include media-breakpoint-down(xl) {
    .page{
        padding-top: 68px;
    }
}

@include media-breakpoint-down(lg) {
    .page{
        padding-top: 68px;
        .page-content .container{
            padding-left: 15px;
        }
    }
}

@include media-breakpoint-down(md) {
    .page{
        .page-content .container{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}