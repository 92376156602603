

// Offer item
.offer-item {
    display: flex;
    align-items: center;
    .offer-content {
        flex: 0 0 40%;
        background-color: $white;
        padding: 50px;
        border:1px solid $gray-300;
        position: relative;
        z-index: 3;
        margin-left: -15%;
        h3{
            font-size: 26px;
        }
        p{
            margin-top: 40px;
        }
        h6{
            color: $primary;
            font-style: italic;
        }
    }
    .offer-image {
       flex: 0 0 75%;
       position: relative;
       z-index: 2;
       //margin-left: -15%;
    }
}

// 
// List offer
//
ul.list-offer {
    margin: 0;
    margin-top: 50px;
    li {
        margin-bottom: 80px;
    }
    li:nth-child(odd) {
        .offer-item {
            .offer-content {
                order: 1;
                margin-left: 0;
            }
            .offer-image {
                flex: 0 0 75%;
                order: 2;
                margin-left: -15%;
            }
        }
    }

    li:nth-child(even) {
        .offer-item {
            .offer-content {
                
            }
            .offer-image {
               
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .offer-item{
        .offer-content{
            padding: 40px;
            p{
                margin-top: 30px;
            }
            h3{
                font-size: 24px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .offer-item{
        .offer-content{
            padding: 25px;
            p{
                margin-top: 15px;
            }
            h3{
                font-size: 20px;
            }
        }
    }
}


@include media-breakpoint-down(md) {
    ul.list-offer{
        margin-top: 0;
        li{
            margin-bottom: 40px;
        }
    }
    .offer-item{
        display: block;
        .offer-image, .offer-content{
            margin-left: 0!important;
            margin-right: 0!important;
            width: 100%;
        }
    }
}


