// colors
$dark:             #040405;
$white:            #fff;
$text-color:       #1f1f21;
$primary:          #46a42c;
$gray-100:         #cccccc;
$gray-200:         #e5e5e5;
$gray-300:         #cecece;
$gray-400:         #f7f7f7;
$gray-500:         #e9eaeb;
$gray-600:         #9d9d9d;




