// 
// page: contact
// 
.contact-data{
    display: flex;
    padding: 20px 0;
    margin: 40px 0;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    .contact-col{
        flex: 1 1 33.333%;
        text-align: center;
        padding: 15px 20px;
        border-right: 1px solid $gray-300;
        p{
            margin: 0;
            font-size: 18px;
            
        }
    }
    .contact-col:first-of-type{
        padding-left: 0;
        text-align: left;
    }
    .contact-col:last-of-type{
        border:0px;
    }
}

.contact-form{
    margin-top: 70px;
    .form-title{
        margin: 50px 0;
        span{
            color: $primary;
            font-style: italic;
            margin-left: 10px;
        }
        h6{
            color: $gray-600;
        }
    }
}


@include media-breakpoint-down(md) {
    .contact-data{
        display: block;
        .contact-col, .contact-col:first-of-type{
            text-align: center;
            border-right: 0px;
            padding: 10px;
        }
    }
    .contact-form .form-title{
        margin: 20px 0;
    }
}

@include media-breakpoint-down(sm) {
    .contact-data{
       margin: 15px 0;
    }
}
