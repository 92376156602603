//
// Cookie
//
.cookie {
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    bottom: -100%;
    padding: 20px;
    background-color: $primary;
    transition: all 0.5s;
    opacity: 0;
    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-text {
        color: #fff;
        margin: 0;
    }
    
    &.show {
        bottom: 0%;
        opacity: 1;
    }
}