body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    color: $text-color;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    color: $dark;
}

h1,
.h1 {
    font-size: 82px;
}

h2,
.h2 {
    font-size: 48px;
}

h3,
.h3 {
    font-size: 36px;
}

h4,
.h4 {
    font-size: 22px;
}

h5,
.h5 {
    font-size: 20px;
}

h6,
.h6 {
    font-size: 18px;
}

p {
    font-size: 16px;
    font-weight: 300;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    color: $dark;
    transition: all 0.35s;
}

a:hover,
a:focus {
    color: $primary;
}

@include media-breakpoint-down(xl) {
    h1,
    .h1 {
        font-size: 72px;
    }

    h2,
    .h2 {
        font-size: 42px;
    }

    h3,
    .h3 {
        font-size: 32px;
    }
}

@include media-breakpoint-down(lg) {
    h1,
    .h1 {
        font-size: 62px;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }
}

@include media-breakpoint-down(md) {
    h1,
    .h1 {
        font-size: 54px;
    }

    h2,
    .h2 {
        font-size: 26px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }
}

@include media-breakpoint-down(sm) {}