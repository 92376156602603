// 
// page: home
// 
// 
.page-home{
    padding-top: 0;
}

//
// section title
//
.section-title{
    h6{
        color: $primary;
        font-style: italic;
    }
}

//
// Swiper hero
// 
.swiper-hero-container {
    position: relative;
    .swiper-nav {
        background-color: transparent;
        color: $white;
    }
    .swiper-nav-prev {
        left: 30px;
    }
    .swiper-nav-next {
        right: 30px;
    }
    .swiper-slide-hero {
        position: relative;
        overflow: hidden;
        .image-holder{
            padding-bottom: 0!important;
            height: 100vh;
        }
        .slide-content {
            position: absolute;
            z-index: 11;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px 50px;
        }
        .image-holder {
            padding-bottom: 43.5%;
        }
        h2,
        h3,
        h4,
        h5 {
            display: inline-block;
            background-color: $white;
            padding: 10px;
            max-width: 640px;
            text-align: center;
        }
        h3 {
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            font-weight: 100;
        }
    }
    .swiper-slide {
        h2,
        h3 {
            opacity: 0;
            transform: translateY(100%);
        }
        h2 {
            transition: all 0.35s 0.3s;
        }
        h3 {
            transition: all 0.35s 0.45s;
        }
    }
    .swiper-slide-active {
        h2,
        h3 {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

//
// home offer
//
.home-list-offer {
    ul.list-offer {
        margin: 0;
        li {
            margin: 0;
        }
    }
    .offer-item .offer-content p{
        padding-right: 10%;
    }
}

// 
// Parallax
// 
.parallax {
    padding-top: 15vh;
    position: relative;
    .parallax-background {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
        background-attachment: fixed;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
    .parallax-content {
        position: relative;
        z-index: 4;
        text-align: center;
        h2,
        h3,
        p {
            color: $white;
        }
        p {
            width: 500px;
            max-width: 90%;
            margin: 0px auto;
            font-size: 16px;
        }
        h3 {
            font-size: 36px;
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
        }
        .parallax-body {
            margin-top: 170px;
            border: 2px solid $white;
            border-bottom: 0px;
        }
        .parallax-icon {
            text-align: center;
            transform: translateY(-50%);
            img {
                border-radius: 50%;
            }
        }
        .btn {
            margin: 70px 0;
        }
    }
}



// 
// Posts
// 
.section-blog{
    padding-top: 80px;
    .blog-more{
        text-align: right;
        transform: translateY(-100%);
    }
}
.swiper-posts-container {
    position: relative;
    .swiper-nav{
        display: none;
    }
}

#swiper-posts{
    .swiper-wrapper{
        justify-content: center;
    }
    .swiper-slide {
        box-sizing: border-box;
        width: 33.333% !important;
        padding: 10px 2.5%;
    }
}

#swiper-posts.swiper-loop{
    .swiper-wrapper{
        justify-content: flex-start;
    }
    .swiper-slide {
        width: 25% !important;
        padding: 10px 2.5%;
        .card {
            opacity: 0.4;
            transition: all 0.4s;
            .image-holder-sm{
                padding-bottom: 82.5%;
            }
        }
        .swiper-slide-wrapper{
            padding-top: 210px;
            transition: all 0.35s;
        }
    }
    .swiper-slide-active, .swiper-slide-prev, .swiper-slide-next {
        .card {
            opacity: 1;
        }
    }

    .swiper-slide-active .swiper-slide-wrapper{
        padding-top: 100px;
    }
    .swiper-slide-prev .swiper-slide-wrapper{
        padding-top: 0;
    }
    .swiper-slide-next .swiper-slide-wrapper{
        padding-top: 0;
    }
}

// 
// Partners
// 
.swiper-prtners-container {
    position: relative;
    .swiper-nav {
        display: none;
    }
}


@include media-breakpoint-down(xl) {
    // home offer
    .home-list-offer{
        .offer-item{
            .image-holder-xs{
                height: 100vh;
                padding: 0;
            }
        }
    }

    // parallax
    .parallax {
        padding-top: 18vh;
        .parallax-content {
            p {
                font-size: 16px;
            }
            h3 {
                font-size: 26px;
            }
            .parallax-body {
                margin-top: 110px;
            }
            .parallax-icon {
                img {
                    max-width: 110px;
                }
            }
            .btn {
                margin: 30px 0;
            }
        }
    }

    // home posts
    #swiper-posts.swiper-loop{
        .swiper-slide {
            padding: 10px 1%;
           .swiper-slide-wrapper{
                padding-top: 80px;
                transition: all 0.35s;
            }

            .card{
                .card-body{
                    padding: 15px 10px;
                }
                .card-title{
                    font-size: 18px;
                    margin: 5px 0;
                }
                .card-text{
                    font-size: 14px;
                }
            }
        }
        .swiper-slide-active .swiper-slide-wrapper{
            padding-top: 40px;
        }
        .swiper-slide-prev .swiper-slide-wrapper{
            padding-top: 0;
        }
        .swiper-slide-next .swiper-slide-wrapper{
            padding-top: 0;
        }
    }
}


@include media-breakpoint-down(lg) {
    // Parallax
    .parallax {
        .parallax-background {
            background-attachment: scroll;
        }
    }

    .home-list-offer{
        .offer-item{
            .image-holder-xs{
                height: auto;
                padding-bottom: 52%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    // hero
    .swiper-hero-container {
        .swiper-nav-prev {
            left: 5px;
        }
        .swiper-slide h3{
            font-size: 18px;
        }
        .swiper-nav-next {
            right: 5px;
        }
    }

    // home offer
    .home-list-offer {
        ul.list-offer {
            li {
                margin: 30px 0px;
            }
        }
        .offer-item{
            .offset-left, .offset-right{
                margin-left: 0!important;
                margin-right: 0!important;
            }
            .offer-content p{
                padding-right: 0%;
            }
        }
    }

    // home blog
    .swiper-posts-container {
        position: relative;
        padding: 10px 15px;

        .swiper-nav-prev {
            left: 25px;
            top: 40%;
        }
        .swiper-nav-next {
            right: 25px;
            top: 40%;
        }
        .swiper-nav{
            display: flex;
        }
    }

    #swiper-posts, #swiper-posts.swiper-loop{
        .swiper-slide {
            padding: 0;
            width: 100%!important;
            .card {
                opacity: 1;
            }
            .swiper-slide-wrapper{
                padding: 10px!important;

                .card .card-title{
                    margin: 20px 0;
                    font-size: 24px;
                }
            }
        }
        
        .swiper-slide-active, .swiper-slide-prev {
            .card {
                opacity: 1;
            }
        }
    }

    // Parallax
    .parallax {
        .parallax-background {
            &:after {
               background-color: rgba(0, 0, 0, 0.75);
            }
        }
        .parallax-content{
            h3{
                font-size: 26px;
            }
            .parallax-icon img{
                max-width: 140px;
            }
            .parallax-body{
                margin-top: 120px;
            }
        }
    }
    
    //Partners
    .swiper-prtners-container {
        position: relative;
        padding: 10px 30px;
        .swiper-nav-prev {
            left: 5px;
        }
        .swiper-nav-next {
            right: 5px;
        }
        .swiper-nav{
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .section-blog{
        padding-top: 40px;
        .blog-more{
            text-align: left;
            transform: translateY(0px);
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}
