// 
// Article
// 
article, .article{
    h1, h2, h3, h4, h5, h6, p{
        margin: 45px 0;
    }
    img{
        margin: 55px 0;
    }
}

@include media-breakpoint-down(lg) {
    article, .article{
        h1, h2, h3, h4, h5, h6, p{
            margin: 25px 0;
        }
        img{
            margin: 35px 0;
        }
    }
}