html {
    box-sizing: border-box;
}

/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
}

// 
// Row sizes
// 
.row-large {
    margin-left: -25px;
    margin-right: -25px;
}
.row-large>.col,
.row-large>[class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
}

.row-small {
    margin-left: -2px;
    margin-right: -2px;
}
.row-small>.col,
.row-small>[class*=col-] {
    padding-right: 2px;
    padding-left: 2px;
    margin-bottom: 4px;
}
