@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-file-picture:before {
    content: "\e927";
}

.icon-file-music:before {
    content: "\e928";
}

.icon-file-play:before {
    content: "\e929";
}

.icon-file-video:before {
    content: "\e92a";
}

.icon-file-zip:before {
    content: "\e92b";
}

.icon-copy:before {
    content: "\e92c";
}

.icon-enlarge2:before {
    content: "\e98b";
}

.icon-shrink2:before {
    content: "\e98c";
}

.icon-upload2:before {
    content: "\e9c6";
}

.icon-download3:before {
    content: "\e9c7";
}

.icon-upload3:before {
    content: "\e9c8";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-attachment:before {
    content: "\e9cd";
}

.icon-eye1:before {
    content: "\e9ce";
}

.icon-eye-plus:before {
    content: "\e9cf";
}

.icon-eye-minus:before {
    content: "\e9d0";
}

.icon-eye-blocked:before {
    content: "\e9d1";
}

.icon-plus:before {
    content: "\ea0a";
}

.icon-minus:before {
    content: "\ea0b";
}

.icon-info:before {
    content: "\ea0c";
}

.icon-cancel-circle:before {
    content: "\ea0d";
}

.icon-cross:before {
    content: "\ea0f";
}

.icon-checkmark:before {
    content: "\ea10";
}

.icon-checkmark2:before {
    content: "\ea11";
}

.icon-facebook:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram1:before {
    content: "\ea92";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-youtube1:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-linkedin:before {
    content: "\eac9";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-lastfm:before {
    content: "\eacb";
}

.icon-lastfm2:before {
    content: "\eacc";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-file-pdf:before {
    content: "\eadf";
}

.icon-file-openoffice:before {
    content: "\eae0";
}

.icon-file-word:before {
    content: "\eae1";
}

.icon-file-excel:before {
    content: "\eae2";
}

.icon-libreoffice:before {
    content: "\eae3";
}

.icon-arrow-bottom:before {
    content: "\e900";
}

.icon-arrow-left:before {
    content: "\e901";
}

.icon-arrow-right:before {
    content: "\e902";
}

.icon-arrow-up:before {
    content: "\e903";
}

.icon-close:before {
    content: "\e904";
}

.icon-cross-out:before {
    content: "\e905";
}

.icon-down-arrow:before {
    content: "\e906";
}

.icon-download:before {
    content: "\e907";
}

.icon-eye:before {
    content: "\e908";
}

.icon-facebook-logo:before {
    content: "\e909";
}

.icon-instagram-logo:before {
    content: "\e90a";
}

.icon-instagram:before {
    content: "\e90b";
}

.icon-magnifying-glass:before {
    content: "\e90c";
}

.icon-pdf:before {
    content: "\e90d";
}

.icon-pin:before {
    content: "\e90e";
}

.icon-search:before {
    content: "\e90f";
}

.icon-youtube-logo:before {
    content: "\e910";
}

.icon-youtube:before {
    content: "\e911";
}