/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "settings";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";

/*!
 * Project:	      Biesiada Catering
 * Version:	      1.0
 * Assigned to:   Comup.pl
*/

// 1. Abstracts
@import "abstracts/variables";
@import "abstracts/sass-rem";
@import "abstracts/mixins";
@import "abstracts/functions";

// 2. Vendors
@import "vendors/hamburgers/hamburgers";
@import "vendors/mfp/main";
@import "vendors/aos/aos";
@import "vendors/swiper";

// 3. Base
@import "base/base";
@import "base/icons";
@import "base/typography";
@import "base/helpers";

// 4. Components
@import "components/bredcrumbs";
@import "components/buttons";
@import "components/dropdown";
@import "components/modal";
@import "components/pager";
@import "components/pagination";
@import "components/cards";
@import "components/image-holder";

@import "components/list";
@import "components/list-offer";
@import "components/list-download";
@import "components/cookie";
@import "components/article";
@import "components/mfp";
@import "components/g-map";
@import "components/forms";
@import "components/swiper";
@import "components/sidebar";
@import "components/aos";

// 5. Layout
@import "layout/page";
@import "layout/header";
@import "layout/offcanvas";
@import "layout/footer";

// 6. Pages
@import "pages/home";
@import "pages/contact";

// 7. Themes
@import "themes/default";




