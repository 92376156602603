// 
// g-map
// 
.page-hero-map{
    position: relative;
    overflow: hidden;
    padding-bottom: 32.5em;
    #map-canvas{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-100;
    }
}
