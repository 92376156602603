// 
// pager
// 
.pager {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 0;
    margin: 20px 0;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
}

.pager-right {
    justify-content: flex-end;
}

.pager-link {
    font-size: 24px;
    color: $white;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    a {
        color: $text-color;
        display: block;
        padding: 0 20px;
        transition: all 0.35s;
    }
    &:hover a {
        color: $primary;
    }
}

.pager-back {
    font-size: 14px;
}

.pager-link.disableed {
    opacity: 0.4;
}

.pager-line {
    display: inline-block;
    width: 1px;
    height: 40px;
    background-color: $gray-200;
}


