// 
// Cards
// 

ul.card-list.row-large{
    li{
        margin-bottom: 45px;
    }
}

.card{
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);
    transition: all 0.35s;
    .card-body{
        padding: 30px 40px;
    }
    .card-date{
        color: $primary;
        font-family: 'Libre Baskerville', serif;
        font-weight: 700;
        font-size: 14px;
    }
    .card-title{
        font-size: 26px;
        margin: 20px 0px 35px 0px;
        transition: all 0.35s;
    }
    .card-text{
        color: $text-color;
    }
}

.card:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .card-title{
        color: $primary;
    }
}


@include media-breakpoint-down(lg) {
    .card{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        .card-body{
            padding: 25px 30px;
        }
    }
}