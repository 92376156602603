//
// Pagination
//
.pagination-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $gray-300;
    padding-top: 20px;
}

.pagination {
    display: inline-flex;
    margin: 0;
    li a {
        padding: 5px;
        font-size: 14px;
        color: $dark;
        font-weight: 400;
        transition: all 0.3s;
        &:hover {
            color: $primary;
        }
    }
    li a.active {
        color: $primary;
        font-weight: 700;
    }
}