// 
// Footer
// 

.footer{
    margin-top: 60px;
    padding: 60px 0;
    border-top: 1px solid $gray-500;
    .footer-row{
        display: flex;
        align-items: center;
    }
    .footer-brand{
        width: 120px;
        flex: 0 0 120px;
    }
    .footer-main{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .copyright{
        display: flex;
        align-items: center;
        p, a{
            font-size: 13px;
            margin: 0;
        }
        a{
            color: rgba( $dark, 0.6 );
            margin-left: 10px;
            &:hover{
                color: $primary;
            }
        }
    }
    ul.footer-nav{
        margin: 0;
        display: flex;
        padding: 20px 10%;
        border-bottom: 1px solid $gray-500;
        margin-bottom: 25px;
        li{
            margin: 0 20px;
        }
        li a{
            font-size: 14px;
            font-family: 'Libre Baskerville', serif;
            font-weight: 400;
        }
    }

    ul.footer-socials{
        display: flex;
        margin: 0;
        li{
            padding: 40px;
            border-left:1px solid $gray-100;
            transition: all 0.35s;
        }
        li a{
            font-size: 18px;
        }
    }
}


@include media-breakpoint-down(lg) {
    .footer{
        .footer-row{
            display: block;
            text-align: center;
        }
        .footer-brand{
            display: block;
            text-align: center;
            margin: 10px auto;
        }

        ul.footer-socials{
            justify-content: center;
            li{
                border:0px;
                padding: 20px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .footer{
        padding-bottom: 0;
        ul.footer-nav{
            display: block;
            text-align: center;
            li{
                margin-bottom: 8px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer{
        .copyright{
            display: block;
            text-align: center;
            a{
                margin: 0;
            }
        }
    }
}
