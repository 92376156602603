// 
// list: download
// 
ul.list-download {
    margin: 60px 0;
    >li {
        margin-bottom: 4px;
    }
}

.download-item {
    background-color: $gray-400;
    display: flex;
    .download-text {
        padding: 10px 25px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 300;
        [class^="icon"] {
            margin-right: 16px;
            font-size: 32px;
        }
    }
    .download-link {
        margin-left: auto;
        background-color: $primary;
        align-items: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        line-height: 1;
        white-space: nowrap;
        transition: all 0.35s;
        color: $white;
        font-size: 14px;
        [class^="icon"] {
            font-size: 26px;
            margin-left: 16px;
        }
    }
    .download-link:hover {
        background-color: darken($primary, 10%);
    }
}

.download-item-center{
    justify-content: center;
    align-items: center;
    
    .download-text{
        font-size: 18px;
        [class^="icon"]{
            margin-left: 15px;
            font-size: 22px;
        }
    }
}


@include media-breakpoint-down(sm) {
    .download-item{
        display: block;
    }
}