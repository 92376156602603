//
// Image holder
//
.image-holder {
    display: block;
    position: relative;
    z-index: 10;
    overflow: hidden;
    &-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-200;
        background-size: cover;
        background-position: 50%;
    }
    &-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 20%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//
// Sizes
//
.image-holder {
    &-xs{
        padding-bottom: 52%;
    }
    &-sm {
        padding-bottom: 60%;
    }
    &-md {
        padding-bottom: 72.5%;
    }
    &-lg {
        padding-bottom: 82.5%;
    }
    &-lg-double {
        padding-bottom: 166%;
    }
    &-xl {
        padding-bottom: 115.5%;
    }
    &-squere {
        padding-bottom: 100%;
    }
}

//
// Overlay
//
.overlay {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-color: rgba(0, 0, 0, 0.34);
        opacity: 0;
    }
}

.overlay:hover{
    &:after {
        opacity: 1;
    }
}



