// 
// Swiper nav
// 
.swiper-nav {
    position: absolute;
    z-index: 33;
    top: 50%;
    transform: translateY(-50%);
    color: $dark;
    font-size: 32px;
    background-color: $white;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: center;
}

.swiper-nav-prev {
    left: 20px;
}
.swiper-nav-next {
    right: 20px;
}

// 
// Swiper blog
// 
.swiper-article-container {
    position: relative;
    .swiper-slide {
        width: 70% !important;
        .image-holder {
            opacity: 0.4;
            transition: all 0.4s;
        }
    }
    .swiper-slide-active {
        .image-holder {
            opacity: 1;
        }
    }
    .swiper-nav-prev {
        left: 15%;
    }
    .swiper-nav-next {
        right: 15%;
    }
    .image-holder-xs{
        padding-bottom: 65%;
    }
}

@include media-breakpoint-down(md) {
    .swiper-nav {
        font-size: 20px;
        width: 32px;
        height: 32px;
    }
}