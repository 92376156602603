// 
// header
// 
.header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background-color: $white;
    padding: 25px 0;
    border-bottom: 1px solid $gray-200;
    transition: all 0.35s;
    .header-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-brand{
        width: 120px;
        flex: 0 0 120px;
        img{
            max-width: 120px;
            transition: all 0.35s;
        }
    }
    .header-group{
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
    }

    #toggle-menu{
        display: none;
    }

    ul.header-nav{
        display: flex;
        margin: 0;
        margin-right: 60px;
        > li{
            margin: 0 20px;
            position: relative;
            padding: 30px 0;
        }
        > li > a{
            font-size: 18px;
            font-family: 'Libre Baskerville', serif;
            font-weight: 400;
            transition: all 0.35s;
        }
        > li > a.current{
            color: $primary;
        }
    }

    ul.header-lang, ul.header-socials{
        display: flex;
        margin: 0;
        li{
            padding: 40px;
            border-left:1px solid $gray-100;
            transition: all 0.35s;
        }
        li a{
            color: $gray-100;
            font-size: 18px;
            &:hover{
                color: $primary;
            }
        }
    }
}

.header-sticky{
    padding: 5px 0;
    .header-brand{
        img{
            max-width: 80px;
        }
    }
    ul.header-nav{
        >li > a{
            padding: 10px 0;
        }
    }
    ul.header-lang, ul.header-socials{
        li{
            padding: 20px 40px;
        }
    }
}


@include media-breakpoint-down(xl) {
    .header{
        ul.header-nav{
            margin-right: 30px;
            > li{
                margin: 0 15px;
            }
            > li > a{
                font-size: 16px;
            }
        }
    }

    .header, .header-sticky{
        padding: 5px 0;
        .header-brand{
            img{
                max-width: 80px;
            }
        }
        ul.header-nav{
            >li > a{
                padding: 10px 0;
            }
        }
        ul.header-lang, ul.header-socials{
            li{
                padding: 10px 15px;
                a{
                    font-size: 16px;
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .header{
        ul.header-nav{
            display: none;
        }
        #toggle-menu{
            display: block;
            margin-left: 15px;
        }
    }

    .header, .header-sticky{
        .header-brand{
            img{
                max-width: 60px;
            }
        }
    }
}
