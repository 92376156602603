// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    border-radius: 0px;
    font-size: 14px;
    padding: 10px 25px;
    color: $dark;
    border:1px solid $dark;
    &:hover, &:focus, &:active{
        background-color: transparent;
        color: $secoundary;
    }
}

.btn-lg{
    padding: 18px 32px;
}

.btn-primary{
    color: $primary;
    border-color: $primary;
    background-color: $white;
    &:hover, &:focus, &:active{
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }
}


.btn-secoundary{
    color: $white;
    border-color: $primary;
    background-color: $primary;
    &:hover, &:focus, &:active{
        background-color: darken($primary, 10%);
        color: $white;
        border-color: darken($primary, 10%);
    }
}

.btn-white{
    color: $white;
    border-color: $white;
    background-color: transparent;
    &:hover, &:focus, &:active{
        background-color: white;
        color: $text-color;
        border-color: $white;
    }
}