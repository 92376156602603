// 
// Sidebar
// 
.sidebar {
    position: absolute;
    top: 0px;
    right: 0;
    width: 220px;
    padding: 20px;
}
.sidebar-border{
    border:1px solid $gray-300;
}

.sidebar-sticky{
    top: 30px;
}

.sidebar-sticky.is_sticky {
    position: fixed;
}

.widget {
    .widget-title {
        margin-bottom: 25px;
    }
    ul.widget-menu {
        li {
            margin: 10px 0;
        }
        li a {
            font-size: 16px;
            font-weight: 300;
        }
        li a:focus{
            color: $text-color;
        }
        li a.active {
            color: $primary;
        }
        
    }
}

.widget + .widget{
    margin-top: 30px;
}

// 
// Recent posts
// 
ul.recent-posts{
    li{
        padding: 15px 0;
        border-bottom: 1px solid $gray-200;
    }
    li:last-child{
        border:0px;
    }
}
.recent-item{
    display: flex;
    .recent-image{
        width: 60px;
        flex: 0 0 60px;
    }
    .recent-content{
        padding: 5px 15px;
        padding-right: 0;
        padding-top: 3px;
        h6{
            font-size: 11px;
            font-family: 'Libre Baskerville', serif;
            font-weight: 400;
            color: $gray-600;
            margin-bottom: 5px;
        }
        p{
            font-size: 13px;
            margin: 0;
        }
    }
}


@include media-breakpoint-down(md) {
    .sidebar{
        position: static;
        width: 100%;
        margin-bottom: 25px;
    }
    .sidebar{
        display: none;
    }
    .sidebar-blog{
        display: block;
    }
}