// 
// Dropdown
// 
.dropdown-menu {
    border-radius: 0px;
    padding: 0;
    border: 1px solid $gray-300;
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.3s;
    visibility: hidden;
}

.has-dropdown-menu:hover{
    .dropdown-menu{
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
    }
}

.dropdown-item {
    padding: 14px 30px;
    font-size: 14px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    background-color: $white;
    transition: all 0.35s;
    border-bottom: 1px solid $gray-200;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: $primary;
    color: $white;
    border-bottom: 1px solid $primary;
}

.dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 0px !important;
}