// 
// Modal
// 
.modal-content {
    border-radius: 0px;
    .modal-header,
    .modal-footer {
        border-radius: 0px;
    }
    .close {
        color: $dark;
        opacity: 1;
        transition: all 0.3s;
        &:hover {
            color: $primary;
        }
    }
}