//
// Bredcrumbs
//
.breadcrumb {
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    align-items: center;
    &-item,
    &-item a {
        font-size: 14px;
        color: $text-color;
        transition: all 0.3s;
    }
    &-item a:hover {
        color: $text-color;
    }
    &-item+&-item::before {
        padding-right: 5px;
        padding-left: 5px;
        content: "-";
    }
    &-item.active {
        color: $primary;
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb {
        display: none;
    }
}