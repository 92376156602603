// 
// Aos
// 


[data-aos="offer-animation"] {
    @media screen and (min-width: 992px) {
        .offer-image{
            opacity: 0;
            transform: translateX(100px);
            opacity: 0;
            transition: all 0.45s;
        }
        .offer-content{
            opacity: 0;
            transform: translateX(-50px);
            opacity: 0;
            transition: all 0.3s 0.25s;
        }
        
        &.aos-animate {
            .offer-image{
                opacity: 1;
                transform: translateX(0px);
                opacity: 1;
            }
            .offer-content{
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}


[data-aos="offer-home-left"] {
    @media screen and (min-width: 992px) {
        .image-holder{
            opacity: 0;
            transform: translateX(100px);
            opacity: 0;
            transition: all 0.45s;
        }
        .offer-content{
            opacity: 0;
            transform: translateX(-50px);
            opacity: 0;
            transition: all 0.3s 0.25s;
        }
        
        &.aos-animate {
            .image-holder{
                opacity: 1;
                transform: translateX(0px);
                opacity: 1;
            }
            .offer-content{
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}

[data-aos="offer-home-right"] {
    @media screen and (min-width: 992px) {
        .image-holder{
            opacity: 0;
            transform: translateX(-100px);
            opacity: 0;
            transition: all 0.45s;
        }
        .offer-content{
            opacity: 0;
            transform: translateX(50px);
            opacity: 0;
            transition: all 0.3s 0.25s;
        }
        
        &.aos-animate {
            .image-holder{
                opacity: 1;
                transform: translateX(0px);
                opacity: 1;
            }
            .offer-content{
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}